<template>
  <base-layout page-title="أدعية مختصرة">
    <ion-grid>
      <ion-row no-padding>
        <ion-col class="ion-activatable" col-6 v-for="item in groupList" :key="item.id" @click="groupClicked(item.id)">
          <div class="group " :class="{active: item.id === selectedId}">
            <p>

              {{item.titleAr}}
            </p>
          </div>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-col>

      </ion-row>
      
    </ion-grid>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="toggleSources">
        <ion-icon :icon="book"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <div class="loading" v-if="isLoading">
      جاري التحميل...
    </div>
    <div v-if="!isLoading">
      <div class="read-and-click-container">
        <span class="read-and-click">قم بالضغط على كل دعاء بعد قراءته</span>
      </div>
      <ion-card class="pray-container ion-activatable" v-for="item in prayerList" :key="item.id" @click="prayerClicked(item.id)" :style="item.style">

        <ion-card-content class="pray-text" :style="item.textStyle">{{item.textAr}}</ion-card-content>
        <ion-card-header v-if="!item.disabled">
          <ion-card-subtitle v-if="showSource" :style="item.textStyle" class="source">{{item.source}}</ion-card-subtitle>
          <ion-card-subtitle class="count" :style="item.textStyle"><span>عدد مرات التكرار: </span><span class="count-number">{{item.count}}</span></ion-card-subtitle>

        </ion-card-header>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-card>
    </div>

    <ion-button v-if="!isLoading" class="reread"  expand="block" @click="reset">أعد قراءة الأدعية</ion-button>

    <div class="empty"></div>
  </base-layout>
</template>

<script >
//import {} from '@ionic/vue';
import BaseLayout from "@/base/BaseLayout.vue";
import axios from "axios";
import { IonRippleEffect,IonButton,IonCard,IonIcon,IonCardSubtitle,IonCardContent,IonCardHeader,IonFabButton,IonFab,IonGrid,IonRow,IonCol } from "@ionic/vue";
import { book } from "ionicons/icons";
export default {
  name: "Prayers",
  components: { BaseLayout, IonRippleEffect,IonIcon,IonButton,IonCard,IonCardSubtitle,IonCardContent,IonCardHeader,IonFabButton,IonFab,IonGrid,IonRow,IonCol },
  data() {
    return {
      groupList: [],
      prayerList: [],
      selectedId: 1,
      isLoading: true,
      showSource: true,
      book: book,
      backupPrayerList: [],
    };
  },
  methods: {
    prayerClicked(id) {
      var clickedPrayer = this.prayerList.find((x) => x.id === id);
      if (clickedPrayer.count > 0) {
        clickedPrayer.count -= 1;
      }
      if (clickedPrayer.count <= 0) {
        clickedPrayer.disabled = true;
        clickedPrayer.style = {
          "background-color": "var(--my-secondary)",
        };
        clickedPrayer.textStyle = {
          color: "var(--my-black)",
        };
      }
    },
    groupClicked(id) {
      if (id != this.selectedId) {
        this.isLoading = true;
        this.selectedId = id;
        this.getPrayersByGroupId(id).then((response) => {
          this.prayerList.length = 0;
          this.prayerList = response;
          this.backupPrayerList = JSON.parse(JSON.stringify(this.prayerList))
          this.isLoading = false;
        });
      }
    },
    getPrayersByGroupId(id) {
      console.log("GETING FROM SERVER");
      return new Promise((resolve, reject) => {
        axios
          .get("https://api.adn12project.com/pray/get/prayers?id=" + id)
          .then((response) => {
            resolve(response.data);
          })
          .catch(() => {
            reject(
              "حدثت مشكلة، الرجاء إعادة التطبيق والتأكد من اتصالك بالإنترنت."
            );
          });
      });
    },
    toggleSources() {
      this.showSource = !this.showSource;
    },
    reset() {
      console.log("RESET");
      
      console.log(this.backupPrayerList[0].count);
      console.log(this.prayerList[0].count);
     this.prayerList = JSON.parse(JSON.stringify(this.backupPrayerList))
    },
      
  },
  mounted() {
    console.log("Mounted");
    axios.get("https://api.adn12project.com/pray/get/groups").then((response) => {
      this.groupList = response.data;
      console.log(this.groupList);
    });
    this.getPrayersByGroupId(1).then((response) => {
      this.prayerList = response;
      console.log("SLICED");
      this.backupPrayerList = JSON.parse(JSON.stringify(this.prayerList))
      
      this.isLoading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
ion-fab-button{
  --background:var(--my-black);
  --border-width:1px;
  --border-style:solid;
  --border-color:white;
}

.group {
  @include border-radius(5px);
  background-color: none;
  //border-bottom:1px solid var(--my-white);
  color:var(--my-white);
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 5px;
  padding-left: 5px;
  font-family: "Almarai";
  --box-shadow:none;
  p {
    text-align: center;
  }
  &:hover {
    border:none;
    background:var(--my-orange);
    color:var(--my-black);
    
  }
}
.active {
  border:none;
    background:var(--my-orange);
    color:var(--my-black);
    
}

.pray-container {
  background-color: var(--my-card-background);
  //border:1px solid #73787a;
   box-shadow: none;
  .pray-text {
    font-family: "Kitab-Regular";
    font-size: 22px;
    text-align: justify;
    color: var(--my-white);
    padding-bottom:15px;
  }
  ion-card-header{
    padding-top:5px;
  }
  .source {
    font-family: "Tajawal";
    font-size: 12px;
    color: var(--my-source);
    text-align: center;
  }
  .count {
    font-family: "Tajawal";
    font-size: 12px;
    color: var(--my-source);;
    text-align: center;
    .count-number {
      color: var(--my-red);
      font-size: 18px;
    }
  }
}
.loading {
  font-family: "Tajawal";
  font-size: 20px;
  color: var(--my-white);
  margin-top: 60%;
  text-align: center;
}
.read-and-click-container {
  margin-top: 10px;
  text-align: right;
  padding-right:20px;
  .read-and-click {
    font-family: "Almarai";
    
    color: var(--my-white);
  }
}
@keyframes click {
  0% {
    background-color: inherit;
    color: inherit;
  }

  50% {
    background-color: var(--my-blue);
    color: var(--my-white);
  }

  100% {
    background-color: inherit;
    color: inherit;
  }
}
.empty {
  height: 20px;
}
.reread {
  margin: 0 15px 0 15px;
  font-family:"Almarai";
  --background:var(--my-orange);
  color:var(--my-black)
}
</style>